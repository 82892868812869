import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { graphql, Link } from "gatsby";
import Col from 'react-bootstrap/Col';
import Headline from "../../components/Headline"
import Teaser from "../../components/Teaser";
import Section from "../../components/Section"
import { getTopicsFromResources } from "../../services/utility";

export default class BonusResources extends React.Component {
  render() {
    let resources = this.props.data.resources.edges.map(({ node }) => node).filter(function(item) {
      return (item.topics && item.topics.length > 0);
    });
    return ( 
      <Layout ensureSignedIn={true}>
        <Seo title="Bonus Video Content" />
        <Section className="white" useCol={false}>
          <Col xs={12} md={12} lg={6}>
            <Headline>Bonus Video Content</Headline>
          </Col>
          <Col xs={12} md={12} lg={6} className="pb-3 pt-lg-2">
              <Link to={"/resources/bonus"} className={"mx-1 badge badge-pill badge-primary active"}>All</Link>
              {getTopicsFromResources(resources).map((topic) => {
                return (
                  <Link to={"/resources/bonus-topic-" + topic.slug} key={"bonus-topic-" + topic.slug} className="mx-1 badge badge-pill badge-primary">{topic.name}</Link>
                );
              })}
          </Col>
          {resources.map((resource) => {
            return (
              <Col md={4} key={"resource-" + resource.slug} className="gridListing mb-4">
                <Teaser content={resource} showDescription={true} />
              </Col>
            );
          })}
        </Section>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    resources: allContentfulVideoResource(filter: {showInLeaders: {eq: true}}, sort: {fields: [sortYear, sortOrder], order: [DESC, ASC]}) {
      edges {
        node {
          ...VideoResource
          topics {
            name
            slug
          }
        }
      }
    }
  }
`